@import "src/scss/module";

.Carousel {
  position: relative;
  --swiper-pagination-color: #{$blue};
  --swiper-pagination-bullet-size: 10px;

  &__nextEl,
  &__prevEl {
    cursor: pointer;
    z-index: #{$zindex-dropdown};
    background: #{$gradient-cyan};
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

    &:hover {
      background: #{$gradient-cyan-270};
    }
  }

  :global {
    .navigation {
      flex-direction: row;
      justify-content: center;
      margin-top: 2rem;
      margin-bottom: -2rem;
    }
  }
}
